window.addEventListener('load', function(){
    $('.menu-btn').on('click', function() {
        
        $(this).toggleClass('menu-btn_active');
        
        $('.menu').addClass('acmenu');
        
        if(!$(this).hasClass('menu-btn_active')){
            $('.menu').removeClass('acmenu');
        }
    });

    window.onscroll = function() {myFunction()};

    var header = document.getElementById("nav_header");
    var sticky = header.offsetTop;
    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        $("main").css("padding-top" , "70px");
      } else {
        header.classList.remove("sticky");
        $("main").css("padding-top" , "0");
      }
    }
});

